

let routeList = [
  {
    name: "main",
    path: "/",
    component: () => import("@/views/home/index.vue"),
  },
  {
    name: "home",
    path: "/home",
    component: () => import("@/views/home/index.vue"),
  },
  // {
  //   name: "home",
  //   path: "/home",
  //   component: () => import("@/views/home/index.vue"),
  // }
];
export default routeList;
