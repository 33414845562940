import { createApp } from "vue";
import App from "./App.vue";
import MetaInfo from "vue-meta-info";
import router from "./router/index.ts";

import hljs from "highlight.js";
// 使用样式，有多种样式可选
import 'highlight.js/styles/atom-one-dark.css';
// 增加自定义命令v-highlight
 
let app = createApp(App)

app.directive("highlight", function(el) {
    let blocks = el.querySelectorAll("pre code");
    blocks.forEach(block => {
        hljs.highlightBlock(block);
    });
});
// // 增加组定义属性，用于在代码中预处理代码格式
// Vue.prototype.$hljs = hljs;


app.use(router)
app.use(MetaInfo)
app.mount('#app')
