<template>
  <n-config-provider :hljs="hljs">
    <router-view></router-view>
  </n-config-provider>
</template>
<script>
import hljs from 'highlight.js/lib/core'
import javascript from 'highlight.js/lib/languages/javascript'
hljs.registerLanguage('javascript', javascript)
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  data() {
    return {
      hljs
    }
  },
  mounted() {
    document.dispatchEvent(new Event("render-event"));
  },
};
</script>
<style>
@font-face {
  font-family: "myFont";
  src: url("./assets/_思源黑体SourceHanSansCN-Light.otf");
  font-stretch: "normal";
  font-weight: 400;
}
body {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  font-family: 'myFont';
}
.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
#app {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: auto;
}
* {
  box-sizing: border-box;
}
*::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
*::-webkit-scrollbar-thumb {
  background-color: #444444;
}
</style>
